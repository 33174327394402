<template>
	<div class="page page-industryprogramme">
		<h2 class="is-title is-inverted is-simple">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: backgroundUrl }"></div>
		<div class="container">
			<div v-if="bannerImg" class="banner-indp">
				<a :href="bannerUrl"><img :src="bannerImg | imageUrl"/></a>
			</div>
			<AgendaContent
				v-if="loaded"
				name="Industry"
				:categories="categories"
				:sessions="sessions"
				:alt-title="false"
				:starred="true"
				:default-category="2"
				default-view="list"
				:show-categories="false"
			/>
		</div>
		<div class="glide-slider-wrapper is-industry">
			<div v-if="imageSlider" ref="ipSlider" class="glide glide-slider">
				<div class="glide__track" data-glide-el="track">
					<ul class="glide__slides">
						<li
							v-for="slide in imageSlider"
							:key="slide.id"
							class="glide__slide"
							:style="{ backgroundImage: slide.background && `url('${$options.filters.imageUrl(slide.background)}')` }"
						>
							<div class="image-slide-content">
								<Link v-if="slide.link" :class="{ 'button is-primary': slide.link.text, 'is-full': !slide.link.text }" :link="slide.link" :analytics="sliderAnalytics(slide)">{{
									slide.link.text
								}}</Link>
							</div>
						</li>
					</ul>
				</div>
				<div class="glide__arrows" data-glide-el="controls">
					<button class="glide__arrow glide__arrow--left" data-glide-dir="<"><inline-svg :src="require('../assets/back.svg')" width="25"></inline-svg></button>
					<button class="glide__arrow glide__arrow--right" data-glide-dir=">"><inline-svg :src="require('../assets/back.svg')" width="25"></inline-svg></button>
				</div>
			</div>
		</div>

		<Sponsors v-if="founderSponsors" :sponsors="founderSponsors" />
	</div>
</template>

<script>
	import Link from '../components/Link';
	import $http from '../utils/http.js';
	import Sponsors from '../components/Sponsors';
	import meta from '../utils/meta.js';
	import AgendaContent from '../components/AgendaContent';
	import Glide from '@glidejs/glide';

	export default {
		name: 'IndustryProgramme',
		components: { AgendaContent, Sponsors, Link },
		data() {
			return {
				title: null,
				subtitle: null,
				background: null,
				founderSponsors: [],
				bannerImg: null,
				bannerUrl: null,
				categories: [],
				sessions: [],
				loaded: false,
				imageSlider: [],
			};
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-industry-programme'), $http.get(`/event-programme`), $http.get(`/session-categories`)]).then(
				$http.spread((page, data, categories) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data, categories);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-industry-programme'), $http.get(`/event-programme`), $http.get(`/session-categories`)]).then(
				$http.spread((page, data, categories) => {
					this.setPage(page);
					this.setData(data, categories);
					next();
				})
			);
		},
		mounted() {
			$http.get('homepage/founders').then(data => {
				this.founderSponsors = data.data.founder_sponsors;
			});
		},

		methods: {
			setPage({ data }) {
				this.title = data.title;
				this.subtitle = data.subtitle;
				this.background = data.background;
				if (data.banner_active) {
					this.bannerImg = data.banner;
					this.bannerUrl = data.banner_url;
				}

				if (data.image_slider) {
					this.imageSlider = data.image_slider;
					let now = new Date();
					this.imageSlider = this.imageSlider.filter(e => {
						return !!(e.visible_from && e.visible_to && new Date(`${e.visible_from} 00:00:00`) <= now && now <= new Date(`${e.visible_to} 23:59:59`));
					});

					this.$nextTick(() => {
						if (this.imageSlider.length > 0) {
							this.glide = new Glide(this.$refs.ipSlider, {
								type: 'slider',
								autoplay: 7000,
								gap: 0,
								swipeThreshold: false,
								dragThreshold: false,
							}).mount();
						}
					});
				}

				meta.setTitle(this.title);
			},
			setData(data, categories) {
				this.sessions = data.data;
				this.categories = categories.data;
				this.loaded = true;
			},
			sliderAnalytics(slide) {
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'App Carousel - Industry Programme',
					eLabel: slide.title ? slide.title : slide.link ? slide.link.text : null,
					Exhibitor: slide.gtm_exhibitor ? slide.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				};
			},
		},
	};
</script>
